<template>
  <div class="app-container">
    <div>
      <el-button v-p="['externaladmin:finishedProductManage:style:add']" size="mini" type="primary" @click="showDialog"
        >新增风格</el-button
      >
    </div>
    <addOrEditStyle :data="curData" :sup_this="sup_this" :type="type" :visible.sync="visible" :form.sync="form" />
    <myHeader v-p="['externaladmin:finishedProductManage:style:search']" @search="search" />
    <PaginationBar :page="page" :size="size" :total="total" @refreshTableEventFun="refreshTableEventFun" />
    <CommonTable
      :tableLoading="tableLoading"
      style="overflow: auto"
      height="400px"
      :selection="true"
      :cols="cols"
      :infoData="data"
    >
      <template #knifeImageSlot="{ scoped }"> </template>
      <template #originalImageSlot="{ scoped }"> </template>
      <template #showImageSlot="{ scoped }"> </template>
      <template #stateSlot="{ scoped }"> {{ getState(scoped) }}</template>
      <template #timeSlot="{ scoped }"> {{ parseTime(scoped.createTime) }}</template>
      <el-table-column align="center" label="操作" width="300px">
        <template slot-scope="{ row, $index }">
          <div class="flex-center-middle">
            <el-popover placement="top" :ref="`popover-${$index}`" width="200" trigger="click">
              <slot name="tip">
                <p style="font-weight: 500; font-size: 16px">提示</p>
                <p v-if="row.isEnabled">您确定要禁用这一行风格吗？</p>
                <p v-else>您确定要启用这一行风格吗？</p>
              </slot>
              <div style="text-align: right">
                <el-button size="mini" @click="onCancel($index, row)">取消</el-button>
                <el-button
                  :loading="row.loading"
                  size="mini"
                  type="primary"
                  @click="onConfirm(row.isEnabled, row, $index)"
                >
                  确定
                </el-button>
              </div>
              <template v-p="['externaladmin:finishedProductManage:style:update']">
                <color-text-btn v-if="row.isEnabled" :loading="row.loading" slot="reference">禁用</color-text-btn>
                <color-text-btn v-else :loading="row.loading" slot="reference">启用</color-text-btn>
              </template>
            </el-popover>
            <el-divider direction="vertical"></el-divider>
            <color-text-btn v-p="['externaladmin:finishedProductManage:style:edit']" @click="onEdit(row)">编辑</color-text-btn>
          </div>
        </template>
      </el-table-column>
    </CommonTable>
    <PaginationBar :page="page" :size="size" :total="total" @refreshTableEventFun="refreshTableEventFun" />
  </div>
</template>

<script>
import { parseTime } from '@/utils'
import addOrEditStyle from './module/addOrEditStyle.vue'
import myHeader from './module/header'
import { initDataMixin } from '@/mixins'
import { cols } from './module/cols'
import { editStyle } from '@/api/finishedProductManageApi'

export default {
  components: { myHeader, addOrEditStyle },
  mixins: [initDataMixin],
  data() {
    return {
      cols,
      url: '/externaladmin/productService/finishedProdTag/list',
      type: 'add',
      loading: false,
      visible: false,
      sup_this: this,
      form: {
        styleName: ''
      },
      curData: {}
    }
  },
  methods: {
    setLoading(data) {
      this.data.map((item) => this.$set(item, 'loading', false))
      this.$set(data, 'loading', true)
    },
    parseTime,
    search(data) {
      this.query = data
      this.searchChange()
    },
    showDialog() {
      this.type = 'add'
      this.visible = true
    },
    setLoadingFalse(row, index) {
      row.loading = false
      this.$refs[`popover-${index}`].doClose()
    },
    async onConfirm(state, row, index) {
      this.$set(row, 'loading', true)
      const message = state ? '禁用成功' : '启用成功'
      const { description, isEnabled } = row
      try {
        //调用接口，如果code == 0则继续
        let { code } = await editStyle({
          description,
          id: row.id,
          isEnabled: !isEnabled
        })
        if (code !== 0) return
        this.setLoadingFalse(row, index)
        this.init()
        this.$message.success(`${message}`)
      } catch (error) {
        this.setLoadingFalse(row, index)
      }
    },
    onCancel(index, row) {
      this.setLoadingFalse(row, index)
      this.$message.warning('您取消了操作')
    },
    onDisable(data) {},
    onEdit(row) {
      this.type = 'edit'
      this.form.styleName = row.description
      this.curData = row
      this.visible = true
    },
    getState({ isEnabled }) {
      return isEnabled ? '启用' : '禁用'
    }
  },
  created() {
    this.query.isEnabled = ''
  }
}
</script>

<style lang="scss" scoped>
.app-container {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  padding: 20px 30px;
  ::v-deep {
    .el-table__fixed {
      bottom: 0 !important;
    }
    .el-table__fixed-body-wrapper .el-table__body {
      padding-bottom: 10px; // 滚动条高度
    }
  }
}
</style>
